// product categories
export const GENDERS = {
    WOMEN: 'women'
};

export const CATEGORIES = {
    DRESSES_AND_SKIRTS: 'dresses_and_skirts',
    ACCESSORIES: 'accessories'
}

export const SEASONS = {
    SS: "spring_summer",
    FW: "fall_winter"
}


export const addCartItem = (items, newItem) => {
    for (let [idx, item] of items.entries()) {
        if (item.title == newItem.title) {
            item.count++;
            return [...items]
        }
    }

    return [...items, newItem]
}

export const removeCartItem = (items, newItem) => {
    for (let [idx, item] of items.entries()) {
        if (item.title == newItem.title) {
            if (item.count > 1) {
                item.count--;
            } else {
                items.splice(idx, 1)
            }
        }
    }
    return [...items];
}
