import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


export default function BlankHeader(props) {
    const { children, title, subtitle } = props;

  return (
      <Box
          sx={(theme) => ({
              width: '100%',
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
          })}
      >
          <Container
              sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  pt: { xs: 14, sm: 20 },
                  pb: { xs: 8, sm: 12 },
              }}
          >
            { children }
          </Container>
      </Box>
  );
}
