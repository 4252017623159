import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from "@mui/material/Stack";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import vm_pink_2_small from '../images/pink-dress/vm_pink_2_small.jpg';
import vm_pink_4_small from '../images/pink-dress/vm_pink_4_small.jpg';
import bal_1 from '../images/balaclava/balaclava_1.jpg';
import bal_2 from '../images/balaclava/balaclava_2.jpg';

export default function ProductShort(props) {
    const navigate = useNavigate();
    const {
        id,
        imagesList,
        title,
        price,
        productSrc,
        count,
        totalPrice
    } = props;

    const [ card, setCard ] = useState({
        imageNum: 0,
        arrowsVisible: false
    });
    // const {title = '', items = [], id} = props;
    // const {cart, updateState} = useContext(UserContext);
    const handleCardClick = () => {
        navigate('/category/women/' + id);
    }

    const handleCardMouseEnter = () => {
        setCard(prevState => ({
            ...prevState,
            imageNum: 1,
            arrowsVisible: true
        }))
    }

    const handleCardMouseLeave = () => {
        setCard(prevState => ({
            ...prevState,
            imageNum: 0,
            arrowsVisible: false
        }))
    }

    const handleImageClickLeft = () => {
        setCard(prevState => {
            let newImageNum;
            if (prevState.imageNum == 0) {
              newImageNum = images.length - 1;
            } else {
             newImageNum =  prevState.imageNum - 1;
            }

            return {
                ...prevState,
                imageNum: newImageNum
            }
        })
    }
    const handleImageClickRight = () => {
        setCard(prevState => {
            let newImangeNum;
            if (prevState.imageNum == images.length - 1) {
                newImangeNum = 0;
            } else {
                newImangeNum = prevState.imageNum + 1;
            }

            return {
                ...prevState,
                imageNum: newImangeNum
            }
        })
    }

    const images = imagesList;

    return (
        <Grid container
              spacing={1}
              display={'flex'}
              sx={{
                  alignItems: "center"
              }}
        >
        {/*<Box*/}
        {/*    sx={{*/}
        {/*        position: 'relative',*/}
        {/*        // width: 400*/}
        {/*        '&:hover': {*/}
        {/*            cursor: 'pointer'*/}
        {/*        },*/}
        {/*    }}*/}
        {/*    onMouseEnter={ handleCardMouseEnter }*/}
        {/*    onMouseLeave={ handleCardMouseLeave }*/}
        {/*>*/}
            <Grid item>
                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: 100 },
                        maxWidth: { xs: 100 },
                    }}
                    alt="The house from the offer."
                    src={ images[card.imageNum] }
                    onClick={ handleCardClick }
                >
                </Box>
            </Grid>
            <Grid item>
                <Typography align='left'>{ title }</Typography>
                <Typography align='right'>{ price }</Typography>
            </Grid>
        {/*</Box>*/}
        </Grid>
    );
}
