import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Product from './Product';
import Typography from "@mui/material/Typography";

function Products(props) {
    const {products, wrap, title} = props;

   return (
       <Box
           id="hero"
           sx={(theme) => ({
               width: '100%',
               // backgroundImage:
               //   theme.palette.mode === 'light'
               //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
               //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
               backgroundSize: '100% 20%',
               backgroundRepeat: 'no-repeat',
           })}
       >
           <Container
               sx={{
                   // display: 'flex',
                   // flexDirection: 'column',
                   // alignItems: 'center',
                   pt: { xs: 2 },
                   pb: { xs: 1 }
               }}
           >
               <Grid container>
                   { title ? <Typography pb={4} variant={'h4'} >{ title }</Typography> : null }
               </Grid>
               <Grid container spacing={4} pb={12} justifyContent='flex-start' wrap={wrap}>
                   {
                       products.map(({id, title, price, imageList, productSrc}) =>
                           <Product
                               id={id}
                               imagesList={ imageList }
                               title={title }
                               price={ price }
                               productSrc={ productSrc }
                           />
                       )
                   }
               </Grid>
           </Container>
       </Box>
   );
}

export default Products;
