import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import CircleIcon from '@mui/icons-material/Circle';
import { useContext } from 'react';
import { UserContext } from '../UserContext';

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import productDb from '../productDb';
import Products from "./Products";


export default function Product() {
    const { id } = useParams();
    const navigate = useNavigate()
    const {cart, updateState} = useContext(UserContext);
    // const {title = '', items = [], id} = props;
    // const {cart, updateState} = useContext(UserContext);

    const { imageList, color, title, price, description, sizes, colors, related } = productDb.filter((p) => p.id == id)[0];

    const [ card, setCard ] = useState({
        imageNum: 0,
        size: null,
        pickSize: false,
        arrowsVisible: false
    });

    const handleImagePreviewClick = (nextImageNum) => {
        setCard((prevState) => ({
            ...prevState,
            imageNum: nextImageNum
        }))
    }

    const handleSizeClick = (nextSize) => {
        setCard((prevState) => ({
            ...prevState,
            size: nextSize,
            pickSize: false
        }));
    }

    const handleColorClick = (nextColor) => {
        navigate("/category/women/" + nextColor);
    }

    const handleCardMouseEnter = () => {
        setCard(prevState => ({
            ...prevState,
            arrowsVisible: true
        }))
    }

    const handleCardMouseLeave = () => {
        setCard(prevState => ({
            ...prevState,
            arrowsVisible: false
        }))
    }

    const handleImageClickLeft = () => {
        setCard(prevState => {
            let newImageNum;
            if (prevState.imageNum == 0) {
                newImageNum = imageList.length - 1;
            } else {
                newImageNum =  prevState.imageNum - 1;
            }

            return {
                ...prevState,
                imageNum: newImageNum
            }
        })
    }
    const handleImageClickRight = () => {
        setCard(prevState => {
            let newImangeNum;
            if (prevState.imageNum == imageList.length - 1) {
                newImangeNum = 0;
            } else {
                newImangeNum = prevState.imageNum + 1;
            }

            return {
                ...prevState,
                imageNum: newImangeNum
            }
        })
    }

    return (
        <Box
            sx={(theme) => ({
                width: '100%',
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Grid container justifyContent='space-around'>
                    <Grid container md={9} spacing={4}>
                        <Grid item md={2} display={{ xs: 'none', md: 'block'}} order={{ md: 1, xs: 2}}>
                            <Stack direction={{ xs: 'row', md: 'column'}} spacing={1}>
                                { imageList.map((image, index) =>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: { xs: "139px", md: "100%"},
                                            width: {xs: "139px", md: "100%" },
                                            borderBottom:  index == card.imageNum ? '3px solid black': '',
                                            cursor: 'pointer'
                                            // maxHeight: { xs: 640, md: 630 },
                                            // maxWidth: { xs: 350, md: 630 },
                                        }}
                                        alt={ card.title }
                                        src={ image }
                                        onClick={() => handleImagePreviewClick(index) }
                                    />)
                                }
                            </Stack>

                        </Grid>
                        <Grid item md={10} order={{ xs: 1, md: 2}}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    // width: 400
                                    '&:hover': {
                                        cursor: 'pointer'
                                    },
                                }}
                                onMouseEnter={ handleCardMouseEnter }
                                onMouseLeave={ handleCardMouseLeave }
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        height: '100%',
                                        width: '100%'
                                        // maxHeight: { xs: 640, md: 630 },
                                        // maxWidth: { xs: 350, md: 630 },
                                    }}
                                    alt="The house from the offer."
                                    src={ imageList[ card.imageNum ] }
                                    onClick={ () => {} }
                                />
                                {
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            bottom: '10px',
                                            display: {xs: 'block', md: 'none' }
                                        }}
                                    >
                                        {
                                            imageList.map((img, idx) => (
                                                <CircleIcon
                                                    sx={{
                                                        transform: idx == card.imageNum ? 'scale(0.25)' : 'scale(0.2)',
                                                        color: idx == card.imageNum ? 'black' : 'gray'
                                                    }}
                                                />
                                            ))
                                        }
                                    </Box>
                                }
                                { card.arrowsVisible ?
                                    <ChevronLeftOutlinedIcon
                                        sx={{
                                            position: "absolute",
                                            top: '45%',
                                            left: '5px',
                                            zIndex: '9999',
                                            '&:hover': {
                                                backgroundColor: '#fffe'
                                            },
                                            fontWeight: 100,
                                            backgroundColor: '#fff9',
                                            borderRadius: '50%'
                                        }}
                                        onClick={handleImageClickLeft}/> : null
                                }
                                {card.arrowsVisible ?
                                    <ChevronRightOutlinedIcon
                                        sx={{
                                            position: "absolute",
                                            top: '45%',
                                            right: '5px',
                                            zIndex: '9999',
                                            '&:hover': {
                                                backgroundColor: '#fffe'
                                            },
                                            backgroundColor: '#fff9',
                                            borderRadius: '50%'
                                        }}
                                        onClick={handleImageClickRight}/> : null
                                }
                            </Box>
                        </Grid>
                        {
                            related.length ?
                                <Grid item md={12} display={{xs: 'none', md: 'block'}} order={{ xs: 3, md: 3}}>
                                    <Products
                                        wrap={'nowrap'}
                                        products={related}
                                        title={'Идеальная пара'}
                                    />
                                </Grid> : null
                        }
                    </Grid>
                    <Grid container md={3}>
                        <Grid item xs={12} md={12}  order={{ xs: 3, md: 3}}>
                            <Box
                                sx={{
                                    backgroundColor: "#fffcf7",
                                    padding: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        paddingBottom: 2
                                    }}
                                >
                                    <Typography align='left' variant='h6'>
                                        {title}
                                    </Typography>
                                    <Typography align='left'>
                                        {price}
                                    </Typography>
                                </Box>
                                <Divider/>
                                <Box
                                    sx={{
                                        paddingBottom: 2,
                                        paddingTop: 1
                                    }}
                                >
                                    <Typography align='left'>
                                        Цвет
                                    </Typography>
                                    <Typography align='left'>
                                        { color }
                                    </Typography>
                                    <Grid container md={12} >
                                        {
                                            productDb.filter(product => colors.indexOf(parseInt(product.id)) != -1).map(
                                                productOfAnotherColor => (
                                                    <Grid
                                                        display='flex'
                                                        alignItems='center'
                                                        justifyContent='center'
                                                        item
                                                        m={1}
                                                        md={3}
                                                        sx={{
                                                            cursor: 'pointer'
                                                            // borderBottom: card.size && card.size == size ? '3px solid black' : ''
                                                        }}
                                                        onClick={() => {handleColorClick(productOfAnotherColor.id) }}
                                                    >
                                                        <Box
                                                            component='img'
                                                            height='50px'
                                                            width='50px'
                                                            sx={{
                                                                borderBottom: productOfAnotherColor.id == id ? '3px solid black' : ''
                                                            }}
                                                            src={productOfAnotherColor.imageList[0]}
                                                        />
                                                    </Grid>
                                                ))}
                                    </Grid>
                                </Box>
                                <Divider/>
                                <Box
                                    sx={{
                                        paddingBottom: 2,
                                        paddingTop: 1
                                    }}
                                >
                                    <Typography align='left'>
                                        Размер
                                    </Typography>
                                    {
                                        card.pickSize ?
                                            <Typography
                                                align='right'
                                                color={'red'}
                                                fontSize={'12px'}
                                            >
                                                Укажите размер
                                            </Typography> : null
                                    }
                                    <Grid container md={12} xs={12}>
                                        { sizes.map((size) => (

                                            <Grid
                                                display='flex'
                                                alignItems='center'
                                                justifyContent='center'
                                                item
                                                m={1}
                                                md={2}
                                                xs={2}
                                                sx={{
                                                    height: '50px',
                                                    border: '1px solid black',
                                                    cursor: 'pointer',
                                                    borderBottom: card.size && card.size == size ? '3px solid black' : ''
                                                }}
                                                onClick={() => {
                                                    handleSizeClick(size) }}
                                            >
                                                { size }
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                                <Divider/>
                                <Box
                                    sx={{
                                        // width: '100%',
                                        // backgroundColor: 'gray',
                                        // position: {
                                        //     xs: 'fixed'
                                        // },
                                        // bottom: {
                                        //     xs: 0
                                        // },
                                        // display: {}
                                        padding: 0,
                                        margin: 0
                                    }}
                                >
                                    <Button
                                        sx={{
                                            color: 'white',
                                            backgroundColor: '#444444',
                                            borderColor: 'black',
                                            mt: 2,
                                            mb: 2
                                        }}
                                        variant='contained'
                                        // color='primary'
                                        onClick={() => {
                                            if (card.size == null) {
                                              setCard((prevState) => ({...prevState, pickSize: true }));
                                              return;
                                            }

                                            const productFromDb = productDb.filter((p) => p.id == id)[0];
                                            const productToAdd = {
                                                id: productFromDb.id,
                                                title: productFromDb.title,
                                                imageList: productFromDb.imageList,
                                                price: productFromDb.price,
                                                size: card.size,
                                                color: card.color
                                            }

                                            console.log(JSON.stringify(productToAdd));

                                            updateState({cart: {...cart, products: [...cart.products, productToAdd] } })
                                        }}
                                    >
                                        <ShoppingBagIcon
                                            fontSize={'small'}
                                            sx={{
                                                color: '#f6f1eb',
                                                marginRight: 1
                                            }}
                                        />
                                        Добавить в корзину
                                    </Button>
                                </Box>
                                <Divider/>
                                <Box
                                    sx={{
                                        paddingBottom: 2,
                                        paddingTop: 1
                                    }}
                                >
                                    <Typography align='left'>
                                        { description }
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    </Grid>
                    {/*<Grid item display={{ xs: 'none', md: 'block'}} md={12}>*/}

                    {/*</Grid>*/}
            </Container>
        </Box>

        // <Box
        //     sx={{
        //         position: 'relative',
        //         width: 400
        //     }}
        //     onClick={ handleProductClick }
        // >
        //     <Box
        //         component="img"
        //         sx={{
        //             height: 400,
        //             width: 400,
        //             maxHeight: { xs: 640, md: 630 },
        //             maxWidth: { xs: 350, md: 630 },
        //         }}
        //         alt="The house from the offer."
        //         src={ "https://assets.hermes.com/is/image/hermesproduct/4H0104DBMV_worn_1?size=3000,3000&extend=0,0,0,0&align=0,0&$product_item_grid_g$&wid=700&hei=700"}
        //     />
        //     <ChevronLeftOutlinedIcon
        //         sx={{
        //             position: "absolute",
        //             top: '50%',
        //             left: '5px',
        //             zIndex: '9999',
        //             '&:hover': {
        //                 backgroundColor: '#fffe'
        //             },
        //             backgroundColor: '#fff9',
        //             borderRadius: '50%'
        //         }}
        //         onClick={() => { console.log("click left")}}/>
        //     <ChevronRightOutlinedIcon
        //         sx={{
        //             position: "absolute",
        //             top: '50%',
        //             right: '5px',
        //             zIndex: '9999',
        //             '&:hover': {
        //                 backgroundColor: '#fffe'
        //             },
        //             backgroundColor: '#fff9',
        //             borderRadius: '50%'
        //         }}
        //         onClick={() => { console.log("click right")}}/>
        //         <Typography align='left'>Cashemere coat</Typography>
        //         <Typography align='left'>$ 8,750</Typography>
        // </Box>
    );
}
