import React from 'react';
import { useState } from 'react';
import { UserContext, defaultState } from './UserContext';

export const UserContextProvider = (props) => {

    /**
     * Using react hooks, set the default state
     */
    const [state, setState] = useState(defaultState);

    /**
     * Declare the update state method that will handle the state values
     */
    const updateState = (newState) => {
        setState({ ...state, ...newState });
    };


    /**
     * Context wrapper that will provider the state values to all its children nodes
     */
    return (
        <UserContext.Provider value={{ ...state, updateState }}>
            {props.children}
        </UserContext.Provider>
    );
};
