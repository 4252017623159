import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import img from '../img.png';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { UserContext } from '../UserContext';
import { colorNameToCode } from '../productDb';

import { addCartItem } from '../utils';


export default function CategoryHeader(props) {
    const { children, title, subtitle, products } = props;

    const DEFAULT_FILTERS = {
        colors: {
            isEnabled: false,
            isOpened: false,
            data: []
        },
        categories: {
            isEnabled: false,
            isOpened: false,
            data: []
        },
        sizes: []
    }
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

  return (
      <Box
          sx={(theme) => ({
              width: '100%',
              backgroundSize: '100% 20%',
              backgroundRepeat: 'no-repeat',
          })}
      >
          {/*<Container*/}
          {/*    container*/}
          {/*>*/}
          {/*</Container>*/}
          <Box
              sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexDirection: 'column',
                  pt: { xs: 14, sm: 20 },
                  pb: { xs: 8, sm: 12 },
              }}
          >
              <Box
                  sx={{
                      width: '100%'
                  }}
              >
                  <Container>
                      <Container>
                          <Typography variant='body1' align='left' width='100%'>
                              {title}
                          </Typography>
                      </Container>
                      <Container>
                          <Typography variant='h6' align='left' width='100%'>
                              {subtitle}
                          </Typography>
                      </Container>
                  </Container>
              </Box>
              <Box
                  sx={{
                      backgroundColor: "#fffcf7",
                      height: '50px',
                      width: '100%'
                  }}
              >
                  <Container
                      sx={{
                          display: 'flex',
                          // flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          height: '100%'
                          // pt: { xs: 14, sm: 20 },
                          // pb: { xs: 8, sm: 12 },
                      }}
                  >
                      <Box
                          sx={{
                              display: 'flex',
                              // flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: '100%'
                              // pt: { xs: 14, sm: 20 },
                              // pb: { xs: 8, sm: 12 },
                          }}
                      >
                          <Container
                                     sx={{
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'flex-start',
                                         userSelect: 'none',
                                         '&:hover': {
                                             cursor: 'pointer'
                                         },
                                         width: "100%"
                                     }}
                          >
                              <Typography
                                onClick={() => {
                                    setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                                }}
                              >{`Цвет ${filters.colors.data.length ? "(" + filters.colors.data.length + ")" : ""}`}</Typography>
                              { !filters.colors.isOpened ?
                                  <ExpandMoreIcon fontSize='small'
                                      onClick={ () => {
                                          setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                                      }}/> :
                                  <ExpandLessIcon fontSize='small'
                                      onClick={ () => {
                                          setFilters((prevState) => ({...prevState, colors: { ...prevState.colors, isOpened: !prevState.colors.isOpened}}))
                                      }}/>
                                }
                          </Container>
                          <Container
                              sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  userSelect: 'none',
                                  '&:hover': {
                                      cursor: 'pointer'
                                  }
                              }}
                          >
                              <Typography
                                  onClick={() => {
                                      setFilters((prevState) => ({...prevState, categories: { ...prevState.categories, isOpened: !prevState.categories.isOpened}}))
                                  }}
                              >
                                  Категория
                              </Typography>
                              { !filters.categories.isOpened ?
                                  <ExpandMoreIcon fontSize='small'
                                                  onClick={ () => {
                                                      console.log("category opened");
                                                      setFilters((prevState) => ({...prevState, categories: { ...prevState.categories, isOpened: !prevState.categories.isOpened}}))
                                                  }}/> :
                                  <ExpandLessIcon fontSize='small'
                                                  onClick={ () => {
                                                      console.log("category closed");
                                                      setFilters((prevState) => ({...prevState, categories: { ...prevState.categories, isOpened: !prevState.categories.isOpened}}))
                                                  }}/>
                              }
                          </Container>
                          <Container> <Typography >Материал</Typography> </Container>
                          <Container> <Typography >Размер</Typography> </Container>
                      </Box>
                      <Box
                          sx={{
                              marginLeft: "auto",
                              display: 'flex',
                              // flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              height: '100%'
                          }}
                      >
                          <Container
                              sx={{
                                  marginLeft: 'auto'
                              }}
                          >
                              <Typography
                                  sx={{
                                      borderRight: "1px solid gray",
                                      paddingRight: '10px'
                                  }}
                              >
                                   Очистить
                              </Typography>
                          </Container>
                          <Container>
                              <Typography
                                  sx={{
                                      whiteSpace: 'nowrap'
                                  }}
                              >
                                  Сортировать по
                              </Typography>
                          </Container>
                      </Box>
                  </Container>
              {
                  filters.colors.isOpened ?
                      <Box
                          sx={{
                              backgroundColor: "#fffcf7",
                              borderTop: '1px solid #f6f1eb',
                              height: '100px',
                              width: '100%',
                              zIndex: '999'
                          }}
                      >
                          <Container
                              sx={{
                                  display: 'flex',
                                  // flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  height: '100%'
                                  // pt: { xs: 14, sm: 20 },
                                  // pb: { xs: 8, sm: 12 },
                              }}
                          >
                          <Container
                              sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center'
                              }}
                          >
                              <Grid container>
                                  {
                                      products.map((p) => p.color)
                                          .filter(function onlyUnique(value, index, array) {
                                              return array.indexOf(value) === index;
                                          })
                                          .map(color => {
                                              return (
                                                  <Grid item >
                                                      <Box
                                                          sx={{
                                                              display: "inline-flex",
                                                              alignItems: "center",
                                                              flexDirection: "flex-start",
                                                              marginRight: "30px",
                                                              '&:hover': {
                                                                  cursor: 'pointer'
                                                              },
                                                          }}
                                                          onClick={() => {}}
                                                      >
                                                          <Box
                                                              sx={{
                                                                  width: "16px",
                                                                  display: "inline-block",
                                                                  height: "16px",
                                                                  backgroundColor: colorNameToCode[color],
                                                                  border: "1px solid gray",
                                                                  marginRight: "10px"
                                                              }}
                                                          />
                                                      <Typography
                                                          sx={{
                                                              display: "inline-block"
                                                          }}
                                                      >
                                                          {color}
                                                      </Typography>
                                                      </Box>
                                                  </Grid>);
                                          })
                                  }
                              </Grid>
                          </Container>
                      </Container>
                      </Box>
                      : null
              }
              <Container
                  sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                      // pt: { xs: 14, sm: 20 },
                      // pb: { xs: 8, sm: 12 },
                  }}
              >
                  { children }
              </Container>
              </Box>
          </Box>
      </Box>
  );
}
