import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useContext } from 'react';
import { UserContext } from '../UserContext';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CircleIcon from '@mui/icons-material/Circle';

import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import productDb from '../productDb';
import Products from "./Products";
import ProductShort from "./ProductShort";
import IconButton from "@mui/material/IconButton";
import {ShoppingBag} from "@mui/icons-material";


export default function Cart() {
    const navigate = useNavigate();
    const {cart, updateState} = useContext(UserContext);
    const [ paymentDetails, setPaymentDetailes ] = useState({
        isPaymentDetailsStep: false
    })

    return (
        <Box
            sx={(theme) => ({
                width: '100%',
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Grid
                    container
                    justifyContent='space-between'
                >
                    <Grid
                        xs={12}
                        sx={{
                            pb: 4
                        }}
                    >
                        <Stepper activeStep={1}>
                            <Step key={'cart'}>
                                <StepLabel>Корзина</StepLabel>
                            </Step>
                            <Step key={'Checkout'}>
                                <StepLabel>Оплата</StepLabel>
                            </Step>
                            <Step key={'Confirmation'}>
                                <StepLabel>Подтверждение</StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                    { !cart.products.length ?
                        <Grid
                            container md={8}
                            sx={{
                                p: { xs: 1}
                            }}
                        >
                            <Grid container md={12}
                                  sx={{
                                      backgroundColor: "#fff",
                                      position: 'relative',
                                      height: "500px",
                                      // padding: "10px"
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                  }}
                            >
                                <Box>
                                    <Typography variant='h6'>
                                        Корзина пуста
                                    </Typography>
                                </Box>
                                <Button
                                    sx={{
                                        color: 'white',
                                        backgroundColor: '#444444',
                                        borderColor: 'black',
                                        mt: 2,
                                        mb: 2
                                    }}
                                    variant='contained'
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                >
                                    Продолжить покупки
                                </Button>
                            </Grid>
                        </Grid> :
                        !paymentDetails.isPaymentDetailsStep ?
                        <Grid
                            container md={8}
                            sx={{
                                p: { xs: 1}
                            }}
                        >
                                {
                                    cart.products.reduce((acc, currValue) => {
                                        const productGroupList = acc.filter(pg => currValue.id == pg.id);

                                        if (productGroupList.length) {
                                            productGroupList[0].count++;
                                            productGroupList[0].totalPrice = productGroupList[0].totalPrice + parseInt(currValue.price);
                                        } else {
                                            acc.push({...currValue, count: 1, totalPrice: parseInt(currValue.price)})
                                        }

                                        return acc;
                                    }, []).sort((p1, p2) => p1.id > p2.id).map(({id, title, price, imageList, count, totalPrice }) => (
                                            <Grid container md={12}
                                                  sx={{
                                                      backgroundColor: "#fff",
                                                      position: 'relative',
                                                      // padding: "10px"
                                                      borderTop: "1px solid #f6f1eb",
                                                      padding: 2
                                                  }}
                                            >
                                                <Grid
                                                    xs={9}
                                                    md={6}
                                                    item
                                                >
                                                  <ProductShort
                                                      id={id}
                                                      price={price}
                                                      title={title}
                                                      imagesList={imageList}
                                                      count={count}
                                                      totalPrice={totalPrice}
                                                  />
                                                </Grid>
                                                <Grid item xs={3} md={3}
                                                      display={'flex'}
                                                      alignItems={'center'}
                                                      justifyContent={{
                                                          xs: 'flex-end',
                                                          md: 'flex-start'
                                                      }}
                                                >
                                                    <Box
                                                        sx={{
                                                            border: "1px solid gray",
                                                            borderRadius: "4px",
                                                            height: "30px",
                                                            width: "80px"
                                                        }}
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                        justifyContent={'space-between'}
                                                    >
                                                        <RemoveIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    cursor: 'pointer'
                                                                },
                                                                color: cart.products.filter(product => product.id == id).length == 1 ? 'gray' : 'black'
                                                            }}
                                                            fontSize={'small'}
                                                            onClick={() => {
                                                                const productCount = cart.products.filter(product => product.id == id).length;
                                                                if (productCount > 1) {
                                                                    const productIndex = cart.products.findIndex(product => product.id == id);
                                                                    const newArr = [...cart.products];
                                                                    newArr.splice(productIndex, 1);
                                                                    updateState({cart: {...cart, products: newArr } })
                                                                }
                                                            }}
                                                        />
                                                        { count }
                                                        <AddIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    cursor: 'pointer'
                                                                },
                                                            }}
                                                            fontSize={'small'}
                                                            onClick={() => {
                                                                updateState({cart: {...cart, products: [...cart.products, productDb.filter((p) => p.id == id)[0]] } })
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={3}
                                                      display={'flex'}
                                                      sx={{
                                                          alignItems: "center",
                                                          justifyContent: "flex-end"
                                                      }}
                                                >
                                                    <Box>
                                                        { totalPrice }
                                                    </Box>
                                                </Grid>
                                                <CloseIcon
                                                    fontSize={'small'}
                                                    sx={{
                                                        '&:hover': {
                                                            cursor: 'pointer'
                                                        },
                                                        position: "absolute",
                                                        right: '5px',
                                                        top: '5px'

                                                    }}
                                                    onClick={() => {
                                                        updateState({cart: {...cart, products: [...cart.products.filter((p) => p.id != id)] } })
                                                    }}
                                                />
                                        </Grid>
                                    ))
                                }
                                <Grid
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderTop: "1px solid #f6f1eb",
                                        padding: 2
                                    }}
                                    item
                                    xs={12}
                                    display='flex'
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Typography variant={'h6'} align={'left'}>
                                        Доставка
                                    </Typography>
                                    <Typography variant={'h6'} align={'left'}>
                                        0
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderTop: "1px solid #f6f1eb",
                                        padding: 2
                                    }}
                                    item
                                    xs={12}
                                    display='flex'
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                >
                                    <Typography variant={'h6'} align={'left'}>
                                        Итог
                                    </Typography>
                                    <Typography variant={'h6'} align={'left'}>
                                        {
                                            cart.products.reduce((acc, currValue) => {
                                                acc += parseInt(currValue.price)

                                                return acc;
                                            }, 0)
                                        }
                                    </Typography>
                                </Grid>
                                <Grid
                                    sx={{
                                        backgroundColor: "#fff",
                                        borderTop: "1px solid #f6f1eb",
                                        padding: 2
                                    }}
                                    item
                                    xs={12}
                                    display='flex'
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Box
                                        sx={{
                                            padding: 0,
                                            margin: 0
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                color: 'white',
                                                backgroundColor: '#444444',
                                                borderColor: 'black',
                                                mt: 2,
                                                mb: 2
                                            }}
                                            variant='contained'
                                            onClick={() => {
                                                setPaymentDetailes({isPaymentDetailsStep: true})
                                            }}
                                        >
                                            Перейти к оплате
                                        </Button>
                                    </Box>
                                </Grid>
                        </Grid> :
                        <Grid
                            container
                            justifyContent='space-between'
                        >
                            <Grid
                                container md={8}
                                sx={{
                                p: {xs: 1}
                                }}
                            >
                                <Grid container md={12}
                                    sx={{
                                        backgroundColor: "#fff",
                                        position: 'relative',
                                        height: "500px",
                                        // padding: "10px"
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Box>
                                        <Typography>
                                            К сожалению, на данный момент оплата не доступна
                                        </Typography>
                                    </Box>
                                    <Button
                                        sx={{
                                        color: 'white',
                                        backgroundColor: '#444444',
                                        borderColor: 'black',
                                        mt: 2,
                                        mb: 2
                                    }}
                                        variant='contained'
                                        onClick={() => {
                                        navigate("/")
                                    }}
                                        >
                                        Продолжить покупки
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {/*<Grid container md={4} sx={{p: {xs: 1}}}>*/}
                    {/*    <Grid*/}
                    {/*        sx={{*/}
                    {/*            backgroundColor: "#fff"*/}
                    {/*        }}*/}
                    {/*        item*/}
                    {/*        md={12}*/}
                    {/*    >*/}
                    {/*        Всего*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                {/*    <Grid container md={9} spacing={4}>*/}
                {/*        <Grid item md={2} display={{ xs: 'none', md: 'block'}} order={{ md: 1, xs: 2}}>*/}
                {/*            <Stack direction={{ xs: 'row', md: 'column'}} spacing={1}>*/}
                {/*                { imageList.map((image, index) =>*/}
                {/*                    <Box*/}
                {/*                        component="img"*/}
                {/*                        sx={{*/}
                {/*                            height: { xs: "139px", md: "100%"},*/}
                {/*                            width: {xs: "139px", md: "100%" },*/}
                {/*                            borderBottom:  index == card.imageNum ? '3px solid black': '',*/}
                {/*                            cursor: 'pointer'*/}
                {/*                            // maxHeight: { xs: 640, md: 630 },*/}
                {/*                            // maxWidth: { xs: 350, md: 630 },*/}
                {/*                        }}*/}
                {/*                        alt={ card.title }*/}
                {/*                        src={ image }*/}
                {/*                        onClick={() => handleImagePreviewClick(index) }*/}
                {/*                    />)*/}
                {/*                }*/}
                {/*            </Stack>*/}

                {/*        </Grid>*/}
                {/*        <Grid item md={10} order={{ xs: 1, md: 2}}>*/}
                {/*            <Box*/}
                {/*                sx={{*/}
                {/*                    position: 'relative',*/}
                {/*                    // width: 400*/}
                {/*                    '&:hover': {*/}
                {/*                        cursor: 'pointer'*/}
                {/*                    },*/}
                {/*                }}*/}
                {/*                onMouseEnter={ handleCardMouseEnter }*/}
                {/*                onMouseLeave={ handleCardMouseLeave }*/}
                {/*            >*/}
                {/*                <Box*/}
                {/*                    component="img"*/}
                {/*                    sx={{*/}
                {/*                        height: '100%',*/}
                {/*                        width: '100%'*/}
                {/*                        // maxHeight: { xs: 640, md: 630 },*/}
                {/*                        // maxWidth: { xs: 350, md: 630 },*/}
                {/*                    }}*/}
                {/*                    alt="The house from the offer."*/}
                {/*                    src={ imageList[ card.imageNum ] }*/}
                {/*                    onClick={ () => {} }*/}
                {/*                />*/}
                {/*                {*/}
                {/*                    <Box*/}
                {/*                        sx={{*/}
                {/*                            position: 'absolute',*/}
                {/*                            left: '50%',*/}
                {/*                            bottom: '10px',*/}
                {/*                            display: {xs: 'block', md: 'none' }*/}
                {/*                        }}*/}
                {/*                    >*/}
                {/*                        {*/}
                {/*                            imageList.map((img, idx) => (*/}
                {/*                                <CircleIcon*/}
                {/*                                    sx={{*/}
                {/*                                        transform: idx == card.imageNum ? 'scale(0.25)' : 'scale(0.2)',*/}
                {/*                                        color: idx == card.imageNum ? 'black' : 'gray'*/}
                {/*                                    }}*/}
                {/*                                />*/}
                {/*                            ))*/}
                {/*                        }*/}
                {/*                    </Box>*/}
                {/*                }*/}
                {/*                { card.arrowsVisible ?*/}
                {/*                    <ChevronLeftOutlinedIcon*/}
                {/*                        sx={{*/}
                {/*                            position: "absolute",*/}
                {/*                            top: '45%',*/}
                {/*                            left: '5px',*/}
                {/*                            zIndex: '9999',*/}
                {/*                            '&:hover': {*/}
                {/*                                backgroundColor: '#fffe'*/}
                {/*                            },*/}
                {/*                            fontWeight: 100,*/}
                {/*                            backgroundColor: '#fff9',*/}
                {/*                            borderRadius: '50%'*/}
                {/*                        }}*/}
                {/*                        onClick={handleImageClickLeft}/> : null*/}
                {/*                }*/}
                {/*                {card.arrowsVisible ?*/}
                {/*                    <ChevronRightOutlinedIcon*/}
                {/*                        sx={{*/}
                {/*                            position: "absolute",*/}
                {/*                            top: '45%',*/}
                {/*                            right: '5px',*/}
                {/*                            zIndex: '9999',*/}
                {/*                            '&:hover': {*/}
                {/*                                backgroundColor: '#fffe'*/}
                {/*                            },*/}
                {/*                            backgroundColor: '#fff9',*/}
                {/*                            borderRadius: '50%'*/}
                {/*                        }}*/}
                {/*                        onClick={handleImageClickRight}/> : null*/}
                {/*                }*/}
                {/*            </Box>*/}
                {/*        </Grid>*/}
                {/*        {*/}
                {/*            related.length ?*/}
                {/*                <Grid item md={12} display={{xs: 'none', md: 'block'}} order={{ xs: 3, md: 3}}>*/}
                {/*                    <Products*/}
                {/*                        wrap={'nowrap'}*/}
                {/*                        products={related}*/}
                {/*                        title={'Идеальная пара'}*/}
                {/*                    />*/}
                {/*                </Grid> : null*/}
                {/*        }*/}
                {/*    </Grid>*/}
                {/*    <Grid container md={3}>*/}
                {/*        <Grid item xs={12} md={12}  order={{ xs: 3, md: 3}}>*/}
                {/*            <Box*/}
                {/*                sx={{*/}
                {/*                    backgroundColor: "#fffcf7",*/}
                {/*                    padding: 2*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        paddingBottom: 2*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Typography align='left' variant='h6'>*/}
                {/*                        {title}*/}
                {/*                    </Typography>*/}
                {/*                    <Typography align='left'>*/}
                {/*                        {price}*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*                <Divider/>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        paddingBottom: 2,*/}
                {/*                        paddingTop: 1*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Typography align='left'>*/}
                {/*                        Цвет*/}
                {/*                    </Typography>*/}
                {/*                    <Typography align='left'>*/}
                {/*                        { color }*/}
                {/*                    </Typography>*/}
                {/*                    <Grid container md={12} >*/}
                {/*                        {*/}
                {/*                            productDb.filter(product => colors.indexOf(parseInt(product.id)) != -1).map(*/}
                {/*                                productOfAnotherColor => (*/}
                {/*                                    <Grid*/}
                {/*                                        display='flex'*/}
                {/*                                        alignItems='center'*/}
                {/*                                        justifyContent='center'*/}
                {/*                                        item*/}
                {/*                                        m={1}*/}
                {/*                                        md={3}*/}
                {/*                                        sx={{*/}
                {/*                                            cursor: 'pointer'*/}
                {/*                                            // borderBottom: card.size && card.size == size ? '3px solid black' : ''*/}
                {/*                                        }}*/}
                {/*                                        onClick={() => {handleColorClick(productOfAnotherColor.id) }}*/}
                {/*                                    >*/}
                {/*                                        <Box*/}
                {/*                                            component='img'*/}
                {/*                                            height='50px'*/}
                {/*                                            width='50px'*/}
                {/*                                            sx={{*/}
                {/*                                                borderBottom: productOfAnotherColor.id == id ? '3px solid black' : ''*/}
                {/*                                            }}*/}
                {/*                                            src={productOfAnotherColor.imageList[0]}*/}
                {/*                                        />*/}
                {/*                                    </Grid>*/}
                {/*                                ))}*/}
                {/*                    </Grid>*/}
                {/*                </Box>*/}
                {/*                <Divider/>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        paddingBottom: 2,*/}
                {/*                        paddingTop: 1*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Typography align='left'>*/}
                {/*                        Размер*/}
                {/*                    </Typography>*/}
                {/*                    <Grid container md={12} xs={12}>*/}
                {/*                        { sizes.map((size) => (*/}

                {/*                            <Grid*/}
                {/*                                display='flex'*/}
                {/*                                alignItems='center'*/}
                {/*                                justifyContent='center'*/}
                {/*                                item*/}
                {/*                                m={1}*/}
                {/*                                md={2}*/}
                {/*                                xs={2}*/}
                {/*                                sx={{*/}
                {/*                                    height: '50px',*/}
                {/*                                    border: '1px solid black',*/}
                {/*                                    cursor: 'pointer',*/}
                {/*                                    borderBottom: card.size && card.size == size ? '3px solid black' : ''*/}
                {/*                                }}*/}
                {/*                                onClick={() => {*/}
                {/*                                    handleSizeClick(size) }}*/}
                {/*                            >*/}
                {/*                                { size }*/}
                {/*                            </Grid>*/}
                {/*                        ))}*/}
                {/*                    </Grid>*/}
                {/*                </Box>*/}
                {/*                <Divider/>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        // width: '100%',*/}
                {/*                        // backgroundColor: 'gray',*/}
                {/*                        // position: {*/}
                {/*                        //     xs: 'fixed'*/}
                {/*                        // },*/}
                {/*                        // bottom: {*/}
                {/*                        //     xs: 0*/}
                {/*                        // },*/}
                {/*                        // display: {}*/}
                {/*                        padding: 0,*/}
                {/*                        margin: 0*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Button*/}
                {/*                        sx={{*/}
                {/*                            color: 'white',*/}
                {/*                            backgroundColor: '#444444',*/}
                {/*                            borderColor: 'black',*/}
                {/*                            mt: 2,*/}
                {/*                            mb: 2*/}
                {/*                        }}*/}
                {/*                        variant='outlined'*/}
                {/*                    >*/}
                {/*                        Добавить в корзину*/}
                {/*                    </Button>*/}
                {/*                </Box>*/}
                {/*                <Divider/>*/}
                {/*                <Box*/}
                {/*                    sx={{*/}
                {/*                        paddingBottom: 2,*/}
                {/*                        paddingTop: 1*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <Typography align='left'>*/}
                {/*                        { description }*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Box>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    </Grid>*/}
                    {/*<Grid item display={{ xs: 'none', md: 'block'}} md={12}>*/}

                    </Grid>
            </Container>
        </Box>

        // <Box
        //     sx={{
        //         position: 'relative',
        //         width: 400
        //     }}
        //     onClick={ handleProductClick }
        // >
        //     <Box
        //         component="img"
        //         sx={{
        //             height: 400,
        //             width: 400,
        //             maxHeight: { xs: 640, md: 630 },
        //             maxWidth: { xs: 350, md: 630 },
        //         }}
        //         alt="The house from the offer."
        //         src={ "https://assets.hermes.com/is/image/hermesproduct/4H0104DBMV_worn_1?size=3000,3000&extend=0,0,0,0&align=0,0&$product_item_grid_g$&wid=700&hei=700"}
        //     />
        //     <ChevronLeftOutlinedIcon
        //         sx={{
        //             position: "absolute",
        //             top: '50%',
        //             left: '5px',
        //             zIndex: '9999',
        //             '&:hover': {
        //                 backgroundColor: '#fffe'
        //             },
        //             backgroundColor: '#fff9',
        //             borderRadius: '50%'
        //         }}
        //         onClick={() => { console.log("click left")}}/>
        //     <ChevronRightOutlinedIcon
        //         sx={{
        //             position: "absolute",
        //             top: '50%',
        //             right: '5px',
        //             zIndex: '9999',
        //             '&:hover': {
        //                 backgroundColor: '#fffe'
        //             },
        //             backgroundColor: '#fff9',
        //             borderRadius: '50%'
        //         }}
        //         onClick={() => { console.log("click right")}}/>
        //         <Typography align='left'>Cashemere coat</Typography>
        //         <Typography align='left'>$ 8,750</Typography>
        // </Box>
    );
}
