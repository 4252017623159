import logo from './logo.svg';
import './App.css';
import './landing-page/LandingPage'
import LandingPage from "./landing-page/LandingPage";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useState, useContext } from 'react';
import { UserContextProvider } from './UserContextProvider';
import { UserContext } from './UserContext';
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Button from '@mui/material/Button';
import {addCartItem, removeCartItem} from './utils';
import { MuiTelInput } from 'mui-tel-input';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import { defaultState } from './UserContext';
import TextField from '@mui/material/TextField';
import Product from './components/Product';
import Container from "@mui/material/Container";
import AppAppBar from "./components/AppAppBar";
// import ModalClose from '@mui/material/Modal
import balaclava_1 from './images/balaclava/balaclava_1.jpg';
import balaclava_2 from './images/balaclava/balaclava_2.jpg';
import products from './productDb';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import getLPTheme from "./landing-page/getLPTheme";
import Footer from "./components/Footer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: '600px',
    p: 4,
    borderRadius: 2
};

function App(props) {

    const { cart, updateState } = useContext(UserContext);
    const [isOpen, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleSendOrder = async () => {
        try {
            const resp = await axios.post(`/api/v3/orders`, {
                items: cart.items,
                phone: cart.phone,
                delivery: cart.delivery
            })
            updateState({ cart: defaultState.cart})
            setOpen(true);
        } catch (err) {
            throw err;
        }
    }

    const handlePhoneChange = (value) => {
        updateState({cart: {...cart, phone: value}})
    }

    const lightTheme = createTheme(getLPTheme('light'));

    return (
        <ThemeProvider theme={lightTheme}>
        <div className="App">
            {/*<ThemeProvider theme={getLPTheme('light')}>*/}
            <AppAppBar mode={'light'}/>
            {/*<Box*/}
            {/*    id="hero"*/}
            {/*    sx={(theme) => ({*/}
            {/*        width: '100%',*/}
            {/*        // backgroundImage:*/}
            {/*        //   theme.palette.mode === 'light'*/}
            {/*        //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'*/}
            {/*        //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,*/}
            {/*        backgroundSize: '100% 20%',*/}
            {/*        backgroundRepeat: 'no-repeat',*/}
            {/*    })}*/}
            {/*>*/}
            {/*    <Container*/}
            {/*        sx={{*/}
            {/*            display: 'flex',*/}
            {/*            flexDirection: 'column',*/}
            {/*            alignItems: 'center',*/}
            {/*            pt: { xs: 14, sm: 20 },*/}
            {/*            pb: { xs: 8, sm: 12 },*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Grid container spacing={4} pb={12}>*/}
            {/*            {*/}
            {/*                products.map(({id, title, price, imageList, productSrc}) =>*/}
            {/*                    <Product*/}
            {/*                        id={id}*/}
            {/*                        imagesList={ imageList }*/}
            {/*                        title={title }*/}
            {/*                        price={ price }*/}
            {/*                        productSrc={ productSrc }*/}
            {/*                    />*/}
            {/*                )*/}
            {/*            }*/}
            {/*        </Grid>*/}
            {/*    </Container>*/}
            {/*</Box>*/}
            {/*<Modal*/}
            {/*    open={isOpen}*/}
            {/*    onClose={ handleClose }*/}
            {/*    aria-labelledby="modal-modal-title"*/}
            {/*    aria-describedby="modal-modal-description"*/}
            {/*>*/}
            {/*    <Box sx={style} >*/}
            {/*        <Alert variant="filled" severity="warning">*/}
            {/*            <Box>*/}
            {/*                Добрый день. К сожалению, не сможем сегодня выполнить ваш заказ, так как находимся в стадии технического запуска.*/}
            {/*                Очень ждем вас на следующей неделе*/}
            {/*            </Box>*/}
            {/*        </Alert>*/}
            {/*    </Box>*/}
            {/*</Modal>*/}
            {/*<Drawer*/}
            {/*    className={'Drawer'}*/}
            {/*    anchor="right"*/}
            {/*    sx={{ p: 2, m: 2, maxWidth: "300px", position: 'relative' }}*/}
            {/*    open={cart.open} onClose={() => updateState({ cart: {...cart, open: false } })}*/}
            {/*>*/}
            {/*<div sx={{*/}
            {/*    // position: 'relative'*/}
            {/*}}>*/}
            {/*    <IconButton*/}
            {/*        sx={{*/}
            {/*            position: 'absolute',*/}
            {/*            top: '0',*/}
            {/*            right: '0',*/}
            {/*            zIndex: '99'*/}
            {/*        }}*/}
            {/*        onClick={() => updateState({ cart: {...cart, open: false } })}>*/}
            {/*        <CloseIcon />*/}
            {/*    </IconButton>*/}
            {/*</div>*/}

            {/*<List*/}
            {/*    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', pt: 6 }}*/}
            {/*>*/}
            {/*    <ListItem>*/}
            {/*        <Product/>*/}
            {/*    </ListItem>*/}
            {/*    {cart.items.map((item) => (*/}
            {/*        <ListItem*/}
            {/*            className={'Drawer-Item'}*/}
            {/*            key={item.title}*/}
            {/*        >*/}
            {/*            /!*<Card sx={{ display: 'flex' }}>*!/*/}
            {/*                <Card className={'Card'} >*/}
            {/*                    <Stack direction='row'>*/}
            {/*                        <CardMedia*/}
            {/*                            component="img"*/}
            {/*                            sx={{ width: 151 }}*/}
            {/*                            image={item.imageSrc}*/}
            {/*                            alt="Image of food"*/}
            {/*                        />*/}
            {/*                        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '250px' }}>*/}
            {/*                            <CardContent sx={{ flex: '1' }}>*/}
            {/*                                <Typography component="div">*/}
            {/*                                    {item.title}*/}
            {/*                                </Typography>*/}
            {/*                                <Typography  color="text.secondary" component="div">*/}
            {/*                                    <Button onClick={() => {*/}
            {/*                                        const newItems = removeCartItem(cart.items, item);*/}
            {/*                                        const hasItem = newItems.map(it => it.count).reduce((curr, acc) => curr + acc, 0) != 0;*/}

            {/*                                        if (hasItem) {*/}
            {/*                                            updateState({ cart: {...cart, ...{items: newItems}}})*/}
            {/*                                        } else {*/}
            {/*                                            // updateState({ cart: {...cart, ...{items: newItems, phone: '+7', open: false, deli }}})*/}
            {/*                                            updateState({ cart: defaultState.cart})*/}
            {/*                                        }*/}
            {/*                                    }}>*/}
            {/*                                        <IndeterminateCheckBoxIcon/>*/}
            {/*                                    </Button>*/}
            {/*                                    {item.count}*/}
            {/*                                    <Button onClick={() => updateState({...cart, ...{ items: addCartItem(cart.items, item)}}) }>*/}
            {/*                                        <AddBoxIcon/>*/}
            {/*                                    </Button>*/}
            {/*                                </Typography>*/}
            {/*                            </CardContent>*/}
            {/*                        </Box>*/}
            {/*                    </Stack>*/}
            {/*            </Card>*/}
            {/*        </ListItem>*/}
            {/*    ))}*/}
            {/*    <ListItem>*/}
            {/*        <ButtonGroup className={'ButtonGroup'}>*/}
            {/*            <Button*/}
            {/*                className={'Button'}*/}
            {/*                onClick={*/}
            {/*                () => {*/}
            {/*                    updateState({ cart: {...cart, ...{ delivery: {...cart.delivery,  isPicked: false}}}})*/}
            {/*                }}*/}
            {/*                variant={ cart.delivery.isPicked ? 'outlined' : 'contained'}*/}
            {/*            >Самовывоз</Button>*/}
            {/*            <Button*/}
            {/*                className={'Button'}*/}
            {/*                onClick={*/}
            {/*                    () => {*/}
            {/*                        updateState({ cart: {...cart, ...{ delivery: {...cart.delivery, isPicked: true}}}})*/}
            {/*                    }}*/}
            {/*                variant={ cart.delivery.isPicked ? 'contained' : 'outlined'}*/}
            {/*            >Доставка</Button>*/}
            {/*        </ButtonGroup>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}
            {/*        {*/}
            {/*           cart.delivery.isPicked ?*/}
            {/*               <TextField*/}
            {/*                   className={'Address'}*/}
            {/*                   required*/}
            {/*                   id="outlined-required"*/}
            {/*                   label="Адрес Доставки по Москве до МКАД"*/}
            {/*                   value={ cart.delivery.address }*/}
            {/*                   onChange={(event) => {*/}
            {/*                       updateState({ cart: {...cart, ...{ delivery: { ...cart.delivery, address: event.target.value}}}})*/}
            {/*                   }}*/}
            {/*               /> :*/}
            {/*               <Typography variany={'body2'}>ул. Киевская д. 2 ( 1 минута от ст. м. Киевская) </Typography>*/}

            {/*        }*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}
            {/*        <Grid container>*/}
            {/*            <Grid item>*/}
            {/*                <Typography>Укажите телефон для связи</Typography>*/}
            {/*            </Grid>*/}
            {/*            <Grid item className={'Phone-container'}>*/}
            {/*                <MuiTelInput*/}
            {/*                    className={'Phone'}*/}
            {/*                    value={ cart.phone }*/}
            {/*                    onChange={ handlePhoneChange }*/}
            {/*                />*/}
            {/*            </Grid>*/}

            {/*        </Grid>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}
            {/*        <Typography variant="body2" color="text.secondary">*/}
            {/*            Стоимость заказа: { cart.items.map(item => item.count * item.price).reduce((acc, el) => acc+el, 0)}  рублей*/}
            {/*        </Typography>*/}
            {/*    </ListItem>*/}
            {/*    {*/}
            {/*        cart.delivery.isPicked == true?*/}
            {/*    <ListItem>*/}
            {/*                <Typography variant="body2" color="text.secondary">*/}
            {/*                    Доставка: 300 рублей*/}
            {/*                </Typography>*/}


            {/*    </ListItem> : null*/}
            {/*    }*/}
            {/*    <ListItem>*/}
            {/*        <Typography variant="body2" color="text.secondary">*/}
            {/*            Итого: { cart.items.map(item => item.count * item.price).reduce((acc, el) => acc+el, 0)*/}
            {/*         + parseInt(cart.delivery.isPicked == true ? 300 : 0,10) } рублей*/}
            {/*        </Typography>*/}
            {/*    </ListItem>*/}
            {/*    <ListItem>*/}

            {/*        <Button*/}
            {/*            variant={'contained'}*/}
            {/*            className={'Button'}*/}
            {/*            onClick={handleSendOrder}*/}
            {/*        >Отправить заказ</Button>*/}
            {/*    </ListItem>*/}
            {/*</List>*/}
            {/*<Product/>*/}
            {/*</Drawer>*/}
            {props.children}
          {/*<header className="App-header">*/}
          {/*  <img src={logo} className="App-logo" alt="logo" />*/}
          {/*  <p>*/}
          {/*    Edit <code>src/App.js</code> and save to reload.*/}
          {/*  </p>*/}
          {/*  <a*/}
          {/*    className="App-link"*/}
          {/*    href="https://reactjs.org"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    Learn React*/}
          {/*  </a>*/}
          {/*</header>*/}
          {/*  </ThemeProvider>*/}
            <Footer/>
        </div>
            </ThemeProvider>
  );
}

export default App;
