import * as React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 4,
        // bottom: 0,
        top: 20,
        // border: `1px solid gray`,
        // borderRadius: '25px',
        // padding: '0 4px',
        color: 'black',
        fontSize: '12px',
        backgroundColor: 'transparent'
    },
}));
