import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { pdfjs } from 'react-pdf';
import  { UserContextProvider } from './UserContextProvider';
import App from './App';
import Products from './components/Products';
import ProductDetailed from './components/ProductDetailed';
import LandingPage from './landing-page/LandingPage';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link as RouterLink,
    Navigate,
    useNavigate, useLocation
} from "react-router-dom";
import About from './components/About';
import Delivery from './components/Delivery';
import {CATEGORIES, GENDERS, SEASONS} from "./utils";

import productDb from "./productDb";
import Style from "./components/Style";
import CategoryHeader from "./components/CategoryHeader";
import BlankHeader from "./components/BlankHeader";
import Cart from "./components/Cart";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Router>
              <Routes>
                  {/*<Route path="/login" element={<Login />} />*/}
                  <Route path="/about" element={
                      <UserContextProvider>
                          <App>
                              <About/>
                          </App>
                      </UserContextProvider>
                  } />
                  <Route path="/style" element={
                      <UserContextProvider>
                          <App>
                              <Style/>
                          </App>
                      </UserContextProvider>
                  } />
                  <Route path="/category/women" element={
                      <UserContextProvider>
                          <App>
                              <CategoryHeader
                                  title={'Женщины'}
                                  subtitle={`Платья и юбки (${
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          ).length
                                  })`}
                              >
                                  <Products
                                      products={
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          )
                                  }
                                  />
                              </CategoryHeader>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/accessories" element={
                      <UserContextProvider>
                          <App>
                              <CategoryHeader
                                  title={'Женщины'}
                                  subtitle={`Аксессуары (${
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.ACCESSORIES)
                                          ).length
                                  })`}
                              >
                                  <Products
                                      products={
                                          productDb
                                              .filter(product =>
                                                  product.genders.includes(GENDERS.WOMEN) &&
                                                  product.categories.includes(CATEGORIES.ACCESSORIES)
                                              )
                                      }
                                  />
                              </CategoryHeader>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/dresses" element={
                      <UserContextProvider>
                          <App>
                              <CategoryHeader
                                  title={'Женщины'}
                                  subtitle={`Платья и юбки (${
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          ).length
                                  })`}
                                  products={
                                      productDb
                                          .filter(product =>
                                              product.genders.includes(GENDERS.WOMEN) &&
                                              product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                          )
                                  }
                              >
                                  <Products
                                      products={
                                          productDb
                                              .filter(product =>
                                                  product.genders.includes(GENDERS.WOMEN) &&
                                                  product.categories.includes(CATEGORIES.DRESSES_AND_SKIRTS)
                                              )
                                      }
                                  />
                              </CategoryHeader>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/category/women/:id" element={
                      <UserContextProvider>
                          <App>
                              <ProductDetailed/>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/cart" element={
                      <UserContextProvider>
                          <App>
                              <Cart/>
                          </App>
                      </UserContextProvider>
                  }/>
                  <Route path="/" element={
                      <UserContextProvider>
                          <App>
                              <BlankHeader>
                                  <Products
                                      products={ productDb }
                                  />
                              </BlankHeader>
                          </App>
                      </UserContextProvider>
                  }/>
                  {/*<Route path="*" element={<NotFound/>} />*/}
              </Routes>
      </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
